@font-face {
  font-family: "Exo-semibold";
  src: url("./fonts/Exo2-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "BebasNeue";
  src: url("./fonts/BebasNeue-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Opensans-bold";
  src: url("./fonts/OpenSans-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Opensans";
  src: url("./fonts/OpenSans-Regular.ttf") format("truetype");
}
