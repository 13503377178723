.loader-wrapper {
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00000014;
  z-index: 999;
	left: 0;
	top: 0;
}
.loader {
	width: 100px;
	line-height: 0;
	}
	.loader path {
		opacity: 0;
		transform-origin: center;
		animation: dfs-loader 2s infinite;
	}
  .loader path:nth-child(2) {
    animation-delay: .08s;
  }
  .loader path:nth-child(3) {
    animation-delay: .16s;
  }
  .loader path:nth-child(4) {
    animation-delay: .24s;
  }
@keyframes dfs-loader {
	0% {
		opacity: 0;
		transform: scale(0.8);
	}
	15.4% {
		opacity: 1;
		transform: scale(1);
	}
	61.6% {
		opacity: 1;
		transform: scale(1);
	}
	77% {
		opacity: 0;
		transform: scale(0.8);
	}
}